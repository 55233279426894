













import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from '../types';

@Component({})
export default class ListOfAssetsStateSelect extends Vue {
  @Prop({ type: String }) value?: State | null;
  @Prop({ type: String, default: 'select.listOfAssetsState' }) fieldName!: string;

  list: Array<{ value: State | null, text: string }> = [
    { value: null, text: 'Alle' },
    { value: 'open', text: 'Offen' },
    { value: 'closed', text: 'Beendet' }
  ];

  created() {
    this.$emit('input', this.value);
  }
}
